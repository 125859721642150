var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "review-bg" },
    [
      _c("Navbar"),
      _c(
        "div",
        { staticClass: "review-body" },
        [
          _c("TopBar"),
          _c(
            "div",
            { staticClass: "filter-bar boxarea" },
            [
              _c("SelectLayer"),
              _c(
                "button",
                {
                  on: {
                    click: function($event) {
                      return _vm.move(0)
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("see_review_insight")))]
              )
            ],
            1
          ),
          _vm.bestFactor[0]
            ? _c("section", { staticClass: "summary-bar" }, [
                _c("div", { staticClass: "boxarea summary-box" }, [
                  _c("h2", [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/reviewmind/summary_01.png"),
                        alt: "아이콘"
                      }
                    }),
                    _vm._v(" " + _vm._s(_vm.$t("average_ratings")) + " ")
                  ]),
                  _c("h3", [
                    _vm._v(
                      _vm._s((_vm.totalRating / _vm.totalCount).toFixed(2)) +
                        " / 5"
                    )
                  ]),
                  _c("p", [
                    _c("i", { staticClass: "fas fa-caret-up" }),
                    _vm._v(" " + _vm._s(_vm.compareRating) + "% "),
                    _c("span", [_vm._v(_vm._s(_vm.$t("month_on_month")))])
                  ])
                ]),
                _c("div", { staticClass: "boxarea summary-box" }, [
                  _c("h2", [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/reviewmind/summary_02.png"),
                        alt: "아이콘"
                      }
                    }),
                    _vm._v(" " + _vm._s(_vm.$t("total_reviews")) + " ")
                  ]),
                  _c("h3", [_vm._v(_vm._s(_vm.comma(_vm.totalCount)))]),
                  _c("p", [
                    _c("i", { staticClass: "fas fa-caret-up" }),
                    _vm._v(
                      " " + _vm._s(_vm.totalCount - _vm.lastMonthCount) + " "
                    ),
                    _c("span", [_vm._v(_vm._s(_vm.$t("month_on_month")))])
                  ])
                ]),
                _c("div", { staticClass: "boxarea summary-box" }, [
                  _c("h2", [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/reviewmind/summary_03.png"),
                        alt: "아이콘"
                      }
                    }),
                    _vm._v(" " + _vm._s(_vm.$t("best_key_factor")) + " ")
                  ]),
                  _vm.bestFactor[0]
                    ? _c("h3", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.bestFactor[0]._id === 1
                                ? "General"
                                : _vm.bestFactor[0]._id === 2
                                ? "Size"
                                : _vm.bestFactor[0]._id === 3
                                ? "Material"
                                : _vm.bestFactor[0]._id === 4
                                ? "Quality"
                                : _vm.bestFactor[0]._id === 5
                                ? "Color"
                                : _vm.bestFactor[0]._id === 6
                                ? "Price"
                                : _vm.bestFactor[0]._id === 7
                                ? "Picture"
                                : _vm.bestFactor[0]._id === 8
                                ? "Style"
                                : _vm.bestFactor[0]._id === 9
                                ? "Brand"
                                : _vm.bestFactor[0]._id === 10
                                ? "Shipping"
                                : _vm.bestFactor[0]._id === 11
                                ? "Durability"
                                : _vm.bestFactor[0]._id === 12
                                ? "Problem"
                                : "General"
                            ) +
                            " "
                        )
                      ])
                    : _vm._e(),
                  _c("p", [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.comma(_vm.bestFactor[0].total)) +
                          " " +
                          _vm._s(_vm.$t("reviews"))
                      )
                    ])
                  ])
                ]),
                _c("div", { staticClass: "boxarea summary-box" }, [
                  _c("h2", [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/reviewmind/summary_04.png"),
                        alt: "아이콘"
                      }
                    }),
                    _vm._v(" " + _vm._s(_vm.$t("review_credibility")) + " ")
                  ]),
                  _c("h3", { staticClass: "score" }, [
                    _vm._v(_vm._s(_vm.getScore(_vm.credibility)))
                  ])
                ])
              ])
            : _vm._e(),
          _c("section", { staticClass: "chart" }, [
            _c("div", { staticClass: "boxarea chart-box" }, [
              _c("h2", [
                _c("img", {
                  attrs: {
                    src: require("@/assets/images/reviewmind/chart_01.png"),
                    alt: "아이콘"
                  }
                }),
                _vm._v(" " + _vm._s(_vm.$t("review_summary")) + " ")
              ]),
              _vm.seriesRating.length > 0 && _vm.currentLang == "ko"
                ? _c("p", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.term ? "지난 " + _vm.term + "개월 동안" : "전체"
                        ) +
                        " " +
                        _vm._s(_vm.comma(_vm.ratingTotalCount)) +
                        " " +
                        _vm._s(_vm.$t("comment_2")) +
                        " " +
                        _vm._s(_vm.$t("comment_3")) +
                        " "
                    )
                  ])
                : _vm.seriesRating.length > 0 && _vm.currentLang == "en"
                ? _c("p", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.term
                            ? "You've got " +
                                _vm.comma(_vm.ratingTotalCount) +
                                " reviews last " +
                                _vm.term +
                                " months"
                            : "You've got " +
                                _vm.comma(_vm.ratingTotalCount) +
                                " reviews last months"
                        ) +
                        " "
                    )
                  ])
                : _vm._e(),
              _c(
                "div",
                { staticClass: "barChart" },
                [
                  _vm.seriesRating.length > 0
                    ? _c("Bar-Chart", { attrs: { series: _vm.seriesRating } })
                    : _c("div", { staticClass: "nodata" }, [
                        _vm._v(_vm._s(_vm.$t("no_data")))
                      ])
                ],
                1
              ),
              _c("button", { on: { click: _vm.handleReviews } }, [
                _vm.loading
                  ? _c("i", { staticClass: "el-icon-loading" })
                  : _c("i", { staticClass: "el-icon-document" })
              ])
            ]),
            _c(
              "div",
              { staticClass: "boxarea chart-box" },
              [
                _c("h2", [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/reviewmind/chart_02.png"),
                      alt: "아이콘"
                    }
                  }),
                  _vm._v(" " + _vm._s(_vm.$t("level_satisfaction")) + " "),
                  _vm.sentiment.length > 0
                    ? _c("p", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.comma(_vm.ratingTotalCount)) +
                            " " +
                            _vm._s(_vm.$t("reviews")) +
                            " "
                        )
                      ])
                    : _vm._e()
                ]),
                _vm.sentiment.length > 0
                  ? _c("Semi-Donut", {
                      attrs: { series: _vm.sentiment, point: _vm.centerPoint }
                    })
                  : _vm._e(),
                _vm.sentiment.length > 0
                  ? _c(
                      "div",
                      { staticClass: "confidence" },
                      [
                        _c("h2", [
                          _vm._v(_vm._s(_vm.$t("review_credibility")))
                        ]),
                        _c("el-progress", {
                          attrs: {
                            "text-inside": true,
                            "stroke-width": 20,
                            percentage: _vm.credibilityByTerm,
                            format: _vm.format
                          }
                        }),
                        _c("p", [
                          _vm._v(_vm._s(_vm.getScore(_vm.credibilityByTerm)))
                        ])
                      ],
                      1
                    )
                  : _c("div", { staticClass: "nodata" }, [
                      _vm._v(_vm._s(_vm.$t("no_data")))
                    ])
              ],
              1
            )
          ]),
          _c("section", [
            _c("div", { staticClass: "negative boxarea" }, [
              _c("h2", [
                _c("img", {
                  attrs: {
                    src: require("@/assets/images/reviewmind/chart_04.png"),
                    alt: "아이콘"
                  }
                }),
                _vm._v(" " + _vm._s(_vm.$t("negative_management")) + " "),
                _c(
                  "button",
                  {
                    on: {
                      click: function($event) {
                        return _vm.move(1)
                      }
                    }
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.$t("see_reputation_management")) + " "
                    )
                  ]
                )
              ]),
              _vm.sentiment.length > 0 && _vm.currentLang == "ko"
                ? _c("p", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.term == ""
                            ? "현재까지"
                            : "지난" + _vm.term + "개월동안"
                        ) +
                        " 받은 리뷰 " +
                        _vm._s(_vm.comma(_vm.chartTotalCount)) +
                        "개 중, 별점 3점 이하를 받은 리뷰가 " +
                        _vm._s(_vm.underThree) +
                        "개 입니다. "
                    ),
                    _c("br"),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.term == ""
                            ? "현재까지"
                            : "지난" + _vm.term + "개월동안"
                        ) +
                        " 감성 점수 Negative를 받은 리뷰가 "
                    ),
                    _c("span", [_vm._v(_vm._s(_vm.sentiment_2))]),
                    _vm._v("개 입니다. ")
                  ])
                : _vm.sentiment.length > 0 && _vm.currentLang == "en"
                ? _c("p", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.comma(_vm.underThree)) +
                        " of the reviews " +
                        _vm._s(
                          _vm.term == ""
                            ? "until now"
                            : "in the last " + _vm.term + " months"
                        ) +
                        " rated 3 stars or less. "
                    ),
                    _c("br"),
                    _vm._v(
                      " " +
                        _vm._s(_vm.comma(_vm.sentiment_2)) +
                        " of the reviews " +
                        _vm._s(
                          _vm.term == ""
                            ? "until now"
                            : "in the last " + _vm.term + " months"
                        ) +
                        " rated negative sentiment score. "
                    )
                  ])
                : _vm._e(),
              _c(
                "div",
                { staticClass: "chartBox" },
                [
                  _c("VueApexCharts", {
                    ref: "chart",
                    class: { fullSize: _vm.index == 1 },
                    attrs: {
                      type: "line",
                      height: "260",
                      options: _vm.chartOptions,
                      series: _vm.series
                    }
                  })
                ],
                1
              )
            ])
          ])
        ],
        1
      ),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.modalVisible
          ? _c(
              "div",
              { staticClass: "dim" },
              [
                _c("ReviewDetail", {
                  attrs: { reviewList: _vm.reviewList },
                  on: { close: _vm.close }
                }),
                _c("div", { staticClass: "dimbg", on: { click: _vm.close } })
              ],
              1
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }