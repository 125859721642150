<template>
  <div class="ReviewDetail modal">
    <div class="modal_cover" @click="close"></div>
    <div class="modal_content">
      <div class="m_header">
        <div>{{ this.productName }} 제품 리뷰 상세 데이터</div>
        <button @click="close" class="close">
          <span class="material-icons"> close </span>
        </button>
      </div>

      <!-- <div>
        <ul>
          <li
            style="border: 1px solid #ccc"
            v-for="(item, i) in productList"
            :key="i"
          >
            <span>{{ item.productName }}</span>
            <ul>
              <li v-for="(data, j) in item.productUrlList" :key="j">
                {{ data.url }}
              </li>
            </ul>
            <ul>
              <li v-for="(data, j) in item.competitorUrlList" :key="j">
                {{ data.competitorUrl }}
              </li>
            </ul>
          </li>
        </ul>
      </div>-->
      <div class="m_body">
        <!-- <el-collapse v-model="activeNames" @change="handleChange">
          <el-collapse-item
            v-for="(item, i) in productList"
            :title="item.productName"
            :key="i"
          >
            <div class="urlBox">
              <h2>자사 제품</h2>
              <ul>
                <li>
                  <div>
                    <span>ASIN</span>
                    <p>{{ item.asin }}</p>
                    <button class="delete" @click="removeProduct(item._id)">
                      삭제
                    </button>
                  </div>
                </li>
              </ul>
              <h2>경쟁사 제품</h2>
              <ul>
                <li
                  v-for="(item, i) in productList[0].commpetitorList"
                  :key="i"
                >
                  <div>
                    <span>ASIN</span>
                    <p>{{ item.asin }}</p>
                    <button class="delete" @click="removeProduct(item._id)">
                      삭제
                    </button>
                  </div>
                  <div>
                    <span>제품명</span>
                    <p>{{ item.productName }}</p>
                    <button class="delete" @click="removeProduct(item._id)">
                      삭제
                    </button>
                  </div>
                </li>
              </ul>
              <div>경쟁사 제품이 없습니다. 제품을 등록해주세요.</div>
            </div>
          </el-collapse-item>
          <el-collapse-item title="Product 2" name="2">
            <div class="urlBox">
              <h2>URL <span>(최대 5개)</span></h2>
              <ul>
                <li>1</li>
                <li>2</li>
                <li>3</li>
                <li>4</li>
                <li>5</li>
              </ul>
              <h2>경쟁사 URL <span>(최대 5개)</span></h2>
              <ul>
                <li>1</li>
                <li>2</li>
                <li>3</li>
                <li>4</li>
                <li>5</li>
              </ul>
            </div>
          </el-collapse-item>
        </el-collapse>-->

        <div class="buttonWrap">
          <button :class="{ active: tabOne }" @click="handleTab(5)">
            {{ $t("five_point") }}
          </button>
          <button :class="{ active: tabTwo }" @click="handleTab(4)">
            {{ $t("four_point") }}
          </button>
          <button :class="{ active: tabThree }" @click="handleTab(3)">
            {{ $t("three_point") }}
          </button>
          <button :class="{ active: tabFour }" @click="handleTab(2)">
            {{ $t("two_point") }}
          </button>
          <button :class="{ active: tabFive }" @click="handleTab(1)">
            {{ $t("one_point") }}
          </button>
        </div>

        <div class="reviewList">
          <div>
            <table
              class="tr7"
              v-if="
                reviewList.filter((data) => data.rating == rating).length > 0
              "
            >
              <tr>
                <th>번호</th>
                <th>날짜</th>
                <th>리뷰 소스</th>
                <th>리뷰 내용</th>
                <th>신뢰도</th>
                <th>감성분석</th>
                <th>NPS 점수</th>
              </tr>
              <tr
                v-for="(data, i) in reviewList.filter(
                  (data) => data.rating == rating
                )"
                :key="i"
              >
                <td>{{ i + 1 }}</td>
                <td>
                  {{ moment(data.review_date).format("YYYY.MM.DD") }}
                </td>
                <td>{{ data.review_source }}</td>
                <td>{{ data.review }}</td>
                <td>{{ data.credibility == true ? "신뢰" : "미신뢰" }}</td>
                <td>
                  {{
                    data.sentiment_pred == 0
                      ? "보통"
                      : data.sentiment_pred == 1
                      ? "만족해요"
                      : "별로에요"
                  }}
                </td>
                <td>{{ data.NPS_Score }}</td>
              </tr>
            </table>

            <div class="nodata" v-else>데이터가 없습니다.</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import { eventBus } from "@/main";
import { mapState } from "vuex";
import moment from "moment";
export default {
  props: {
    reviewList: {
      type: Array,
    },
  },
  data() {
    return {
      moment: moment,
      visible: false,
      type: "amazon",
      asin: "",
      rating: 5,
      // reviewList: [],
      activeNames: ["1"],
      index: 0,
      tabOne: true,
      tabFour: false,
      tabThree: false,
      tabTwo: false,
      tabFive: false,
      ownVisible: true,
    };
  },
  // mounted() {
  //   this.getReviews();
  // },
  computed: {
    ...mapState(["userInfo", "productName"]),
  },
  methods: {
    close() {
      this.$emit("close");
    },
    // closeModal() {
    //   eventBus.$emit("closeModal", true);
    // },
    openModal() {
      this.$emit("openModal");
    },
    handleTab(tab) {
      this.tabFive = false;
      this.tabFour = false;
      this.tabTwo = false;
      this.tabThree = false;
      this.tabOne = false;
      this.rating = tab;
      if (tab == 5) {
        this.tabOne = true;
      } else if (tab == 4) {
        this.tabTwo = true;
      } else if (tab == 3) {
        this.tabThree = true;
      } else if (tab == 2) {
        this.tabFour = true;
      } else if (tab == 1) {
        this.tabFive = true;
      }
    },
    Loading() {
      this.$store.dispatch("LOADING", true);
    },
  },
};
</script>
